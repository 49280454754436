var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailInfo",
              attrs: { title: "기계/장비 기본정보", collapsed: true },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.editable && _vm.tabParam.plantName
                  ? _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "사업장",
                            value: _vm.tabParam.plantName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.tabParam.facilityName
                  ? _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "기계명",
                            value: _vm.tabParam.facilityName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.tabParam.deviceNo
                  ? _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "기기번호",
                            value: _vm.tabParam.deviceNo,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "점검 이력 목록",
                tableId: "hazardEquipmentHis",
                columns: _vm.grid.columns,
                data: _vm.tabParam.machineryHistoryModelList,
                usePaging: false,
                checkClickFlag: false,
                isExcelDown: false,
                merge: _vm.grid.merge,
                selection: "multiple",
                rowKey: "saiContructFacilityHistoryId",
              },
              on: { linkClick: _vm.linkClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              label: "추가",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addItem },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              label: "삭제",
                              showLoading: false,
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeItem },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
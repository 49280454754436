<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="기계/장비 기본정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <div v-if="editable && tabParam.plantName" class="col-4">
            <c-label-text title="사업장" :value="tabParam.plantName"></c-label-text>
          </div>
          <div v-if="editable && tabParam.facilityName" class="col-4">
            <c-label-text title="기계명" :value="tabParam.facilityName"></c-label-text>
          </div>
          <div v-if="editable && tabParam.deviceNo" class="col-4">
            <c-label-text title="기기번호" :value="tabParam.deviceNo"></c-label-text>
          </div>
        </template>
      </c-card>
      <c-table
        ref="table"
        title="점검 이력 목록"
        tableId="hazardEquipmentHis"
        :columns="grid.columns"
        :data="tabParam.machineryHistoryModelList"
        :usePaging="false"
        :checkClickFlag="false"
        :isExcelDown="false"
        :merge="grid.merge"
        selection="multiple"
        rowKey="saiContructFacilityHistoryId"
        @linkClick="linkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <c-btn v-if="editable" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
export default {
  name: 'fork-lift-history',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        saiContructFacilityId: '',
        facilityTypeCd: '',
        plantCd: '',
        plantName: '',
        processName: '',
        vendorCd: '',
        vendorName: '',
        facilityTypeName: '',
        facilityName: '',
        deviceNo: '',
        managerNo: '',
        checkValidPeriod: '',
        machineryHistoryModelList: [],
        deleteMachineryHistoryModelList: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: "inspectionUserName",
            field: "inspectionUserName",
            label: "점검자",
            style: 'width:120px',
            align: "center",
            sortable: false,
          },
          {
            name: "inspectionDate",
            field: "inspectionDate",
            label: "점검일",
            style: 'width:120px',
            align: "center",
            type: 'link',
            sortable: false,
          },
          {
            name: "inspectionResultDetail",
            field: "inspectionResultDetail",
            label: "특이사항",
            style: 'width:350px',
            align: "left",
            sortable: false,
          },
        ],
        height: '400px',
        data: [],
      },
      isSave: false,
      getUrl: '',
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.tabParam.machineryHistoryModelList = [
        {
          inspectionUserName: '점검자1',
          inspectionDate: '2022-02-23',
          inspectionResultDetail: '특이사항1',
        },
        {
          inspectionUserName: '점검자2',
          inspectionDate: '2022-03-31',
          inspectionResultDetail: '특이사항2',
        },
        {
          inspectionUserName: '점검자3',
          inspectionDate: '2022-05-02',
          inspectionResultDetail: '특이사항3',
        },
      ]
    },
    addItem() {
      this.linkClick();
    },
    linkClick() {
      this.popupOptions.title = '중장비 이력 상세';
      this.popupOptions.target = () => import(`${'./forkLiftHistoryResult.vue'}`);
      this.popupOptions.param = {
      } 
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closeItemPopup;
    },
    closeItemPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    removeItem() {
    },
  }
};
</script>